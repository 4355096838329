<template>
  <section class="counterparty-activity">
    <CounterpartyActivity
      v-if="merchant"
      :merchant="merchant"
      :counterparty="counterparty"
      :accounts="accounts"
      :owners="owners"
      type="bank_account"
    />
    <span id="bankAccounts">
      <CounterpartyAccounts
        :merchant="merchant"
        :accounts="accounts"
        @accounts-updated="handleAccountsUpdated"
      />
    </span>
  </section>
</template>

<script>
import CounterpartyMixin from '../CounterpartyMixin'
import CounterpartyActivity from './CounterpartyActivity.vue'
import CounterpartyAccounts from './CounterpartyAccounts.vue'

export default {
  name: 'CounterpartyActivities',
  props: {
    merchant: {
      type: Object
    },
    counterparty: {
      type: Object
    },
    accounts: {
      type: Array
    },
    owners: {
      type: Array
    }
  },
  data() {
    return {
      creatingAccount: false
    }
  },
  components: {
    CounterpartyActivity,
    CounterpartyAccounts
  },
  mixins: [CounterpartyMixin],
  methods: {
    handleAccountsUpdated(updatedAccounts) {
      this.$emit('accounts-updated', updatedAccounts)
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.counterparty-activity {
  .card-list-item {
    display: flex !important;
    flex-direction: row !important;
  }
  .loader {
    background: $cool-gray-400;
    width: 100%;
    height: 5rem;
  }
}
</style>
