<template>
  <div>
    <span class="flex mb-2 justify-between items-center">
      <h2 class="mb-0">Settings</h2>
      <SuggestionButton class="md info" @click="() => $store.dispatch('helpTopic', 'payments')">
        Help Documentation
      </SuggestionButton>
    </span>
    <PaymentSettings
      v-if="!loading"
      @change="changeSettings"
      :settings="oSettings"
      :merchant="merchant"
    />

    <div
      v-else
      class="loading-section p-5 my-5 border border-gray flex items-center justify-center"
    >
      <spinner class="info mr-4" :loading="1" size="2em" />
      Saving Settings
    </div>

    <div class="text-right mt-3">
      <Btn
        @click.native="saveCounterpartySettings"
        :disabled="disabled"
        :loading="loading"
        size="lg"
        severity="primary-black"
      >
        Save settings
      </Btn>
    </div>
    <h3 class="mt-8">Transaction Fees</h3>
    <TransactionFeeTable :countryCode="counterpartyCountry" />
  </div>
</template>
<script>
import CounterpartyMixin from '../CounterpartyMixin'
import PaymentSettings from '../../settings/PaymentSettings.vue'
import defaultSettings from '../../settings/defaultSettings'
import SuggestionButton from '../../../ui/SuggestionButton.vue'
import PayfacMixin from '../../PayfacMixin'
import TransactionFeeTable from '../TransactionFeeTable.vue'

export default {
  name: 'CounterpartySettings',
  props: {
    counterparty: {
      type: Object
    },
    merchant: {
      type: Object
    }
  },
  data() {
    return {
      oSettings: defaultSettings,
      loading: 0,
      doesPayeeCoverBolsterFee: true,
      doesPayeeCoverCreditCardFee: false,
      disabled: true
    }
  },
  created() {
    this.loadSettings()
  },
  methods: {
    /**
     * Save and update counterparty settings/details
     */
    async saveCounterpartySettings() {
      try {
        this.loading = 1
        await this.updateCounterpartySettings(this.counterparty.counterparty_id, this.oSettings)
        this.loading = 0
        this.$store.dispatch('alert', {
          message: 'Payment settings have been saved.'
        })
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Payment settings could not be updated.',
          error: true
        })
        this.loading = 0
      }
    },
    /**
     * Combine the retrieved counterparty settings
     * and the settings that have been mapped from session
     */
    async loadSettings() {
      const { oSettings } = this.counterparty
      this.oSettings = oSettings
    },
    changeSettings(changes) {
      this.disabled = false
      this.oSettings = changes
    }
  },
  mixins: [CounterpartyMixin, PayfacMixin],
  components: {
    PaymentSettings,
    SuggestionButton,
    TransactionFeeTable
  }
}
</script>
<style scoped>
.loading-section {
  min-height: 270px;
}
</style>
