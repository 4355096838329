<template>
  <section>
    <h2 class="mb-4">Owners</h2>
    <CounterpartyOwners
      v-if="!updating"
      :owners="owners"
      :isOrganization="isOrganization"
      @removed="onRemoved"
      @show-owner-onboarding="onShowOwnerOnboarding"
    />
    <div
      v-else
      class="loading-section p-5 my-5 border border-gray flex items-center justify-center"
    >
      <spinner class="info mr-4" :loading="1" size="2em" />
      Updating owners
    </div>
    <MiniModal
      ref="ownerModal"
      classes="white"
      :full="false"
      :click-away="false"
      :closeable="true"
      :scrollable="true"
      :size="'md'"
    >
      <OwnerOnboarding
        @close="() => $refs.ownerModal.close()"
        @success="onOwnerOnboardingSuccess"
        :owner="owner"
      />
    </MiniModal>
    <div class="text-right">
      <Btn
        @click="updateOwners"
        :disabled="!updatesHaveBeenMade || updating"
        :loading="updating"
        class="info round"
      >
        Save Owners
      </Btn>
    </div>
  </section>
</template>

<script>
import CounterpartyOwners from '../onboarding/CounterpartyOwners.vue'
import OwnerOnboarding from '../onboarding/OwnerOnboarding.vue'
import OwnerMixin from '../OwnerMixin'

export default {
  name: 'CounterpartyOwnerList',
  data() {
    return {}
  },
  props: {
    counterparty: {
      type: Object
    },
    merchant: {
      type: Object
    },
    existingOwners: {
      type: Array,
      default: () => []
    },
    initialOwners: {
      type: Array,
      default: () => []
    }
  },
  components: {
    CounterpartyOwners,
    OwnerOnboarding
  },
  computed: {
    isOrganization() {
      return this.merchant && this.merchant.type === 'organization'
    }
  },
  mounted() {
    this.owners = this.existingOwners
  },
  mixins: [OwnerMixin]
}
</script>
