<template>
  <section>
    <div class="w-100" v-if="!saving">
      <CounterpartyOnboardingCompany :errors="errors" :counterparty="counterparty" />

      <CounterpartyOnboardingAddress
        reference="company"
        :errors="errors"
        :counterparty="counterparty"
      />
    </div>
    <div
      v-else
      class="loading-section p-5 my-5 border border-gray flex items-center justify-center"
    >
      <spinner class="info mr-4" :loading="1" size="2em" />
      Saving Profile
    </div>

    <div class="text-right">
      <Btn class="info round mt-3" @click="onSave"> Save details </Btn>
    </div>
  </section>
</template>

<script>
import CounterpartyOnboardingCompany from './onboarding/CounterpartyOnboardingCompany.vue'
import CounterpartyOnboardingAddress from './onboarding/CounterpartyOnboardingAddress.vue'
import OnboardingMixin from './onboarding/OnboardingMixin'
import CounterpartyProfileMixin from './CounterpartyProfileMixin'

export default {
  name: 'CounterpartyForm',
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {
      saving: 2
    }
  },
  components: {
    CounterpartyOnboardingCompany,
    CounterpartyOnboardingAddress
  },
  mixins: [OnboardingMixin, CounterpartyProfileMixin],
  methods: {
    /**
     * Handle saving the counterparty changes
     */
    async onSave() {
      try {
        this.saving = 2
        await this.saveDetails(this.counterparty)
        this.saving = 0
      } catch (e) {
        this.saving = 0
      }
    }
  },
  mounted() {
    this.saving = 2
    this.mapSessionDataToCounterparty()
    // combine mapped session and retrieved counterparty data
    this.profile = c.merge(this.counterparty, this.data)
    this.saving = 0
  }
}
</script>

<style scoped>
.loading-section {
  min-height: 500px;
}
</style>
