<template>
  <div>
    <h2 class="mb-4">Profile details</h2>
    <CounterpartyForm v-if="hasCounterparty" :data="merchant" />
  </div>
</template>
<script>
import CounterpartyProfileMixin from '../CounterpartyProfileMixin'
import CounterpartyForm from '../CounterpartyForm.vue'

export default {
  name: 'CounterpartyProfile',
  data() {
    return {
      loading: false
    }
  },
  computed: {
    // Check for loaded counterparty/merchant
    hasCounterparty() {
      return this.merchant && Object.keys(this.merchant).length > 0
    }
  },
  props: {
    merchant: {
      type: Object
    }
  },
  mixins: [CounterpartyProfileMixin],
  components: {
    CounterpartyForm
  }
}
</script>
